
import { Vue, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import { MessagingServiceConfigurationController } from "@/app/ui/controllers/MessagingServiceConfigurationController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import MessagingServiceConfigurationFormMessageInformationSection from "@/app/ui/views/messaging-service-configuration/messaging-service-configuration-form/messaging-service-configuration-form-message-information-section.vue";
import MessagingServiceConfigurationFormMessageInformationSection2 from "@/app/ui/views/messaging-service-configuration/messaging-service-configuration-form/messaging-service-configuration-form-message-information-section-2.vue";
import MessagingServiceConfigurationFormMessageDetailSection from "@/app/ui/views/messaging-service-configuration/messaging-service-configuration-form/messaging-service-configuration-form-message-detail-section.vue";
import {
  MESSAGE_TYPE_OPTIONS,
  parsingValueToNameOptions
} from "@/app/ui/views/messaging-service-configuration/MessagingServiceConfigurationUtils";
import { includes } from "lodash";

@Options({
  components: {
    MessagingServiceConfigurationFormMessageDetailSection,
    MessagingServiceConfigurationFormMessageInformationSection2,
    MessagingServiceConfigurationFormMessageInformationSection,
    DetailLayout
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  }
})
export default class MessagingServiceConfigurationForm extends Vue {
  async mounted() {
    MessagingServiceConfigurationController.initMessageForm();
    if (!this.isFormTypeAdd) {
      await MessagingServiceConfigurationController.fetchDetailOnEdit(
        Number(this.$route.params.id)
      );
    }
  }

  get loadingDetail() {
    return MessagingServiceConfigurationController.isLoading;
  }
  get error() {
    return MessagingServiceConfigurationController.error;
  }
  get errorCause() {
    return MessagingServiceConfigurationController.errorCause;
  }

  get titlePage() {
    return this.isFormTypeAdd ? "Buat Pesan" : "Ubah Pesan";
  }
  get isFormTypeAdd() {
    return this.$route.meta.formType === "add";
  }
  get form() {
    return MessagingServiceConfigurationController.messageServiceForm;
  }
  leavePageConfirmation = false;
  saveConfirmation = false;
  nextPath = "";
  answerLeavingPage = false;
  goBack() {
    let path = "/admin/messaging-service-configuration";
    if (!this.isFormTypeAdd) {
      path += `/${this.$route.params.id}`;
    }
    this.$router.push(path);
  }
  onLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }
  onCloseSuccess() {
    MainAppController.closeMessageModal();
    this.answerLeavingPage = true;
    this.goBack();
  }
  onSaveForm() {
    if (!this.isFormValid) {
      return;
    }
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: this.isFormTypeAdd
          ? "Konfirmasi Pembuatan"
          : "Konfirmasi Perubahan",
        message: this.isFormTypeAdd
          ? `Anda yakin ingin menyimpan pembuatan Konfigurasi Pesan untuk ${parsingValueToNameOptions(
              MESSAGE_TYPE_OPTIONS,
              this.form.messageType
            )} ?`
          : `Anda yakin ingin menyimpan perubahan detail Konfigurasi Pesan untuk ${parsingValueToNameOptions(
              MESSAGE_TYPE_OPTIONS,
              this.form.messageType
            )} ?`,
        textCancel: "Kembali",
        textSuccess: "Simpan",
        onSubmit: this.isFormTypeAdd
          ? () => this.onCallApiCreateMessagingService()
          : () => this.onCallApiPatchMessagingService(),
        onClose: () => MainAppController.closeMessageModal(),
        image: "are-you-sure"
      })
    );
  }
  showSuccessModal() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: this.isFormTypeAdd
          ? "Pembuatan Berhasil !"
          : "Perubahan Berhasil !",
        message: this.isFormTypeAdd
          ? `Konfigurasi Pesan berhasil dibuat.`
          : "Detail Konfigurasi Pesan berhasil diubah.",
        textSuccess: "OK",
        onSubmit: () => this.onCloseSuccess(),
        onClose: () => this.onCloseSuccess(),
        image: "image-modal-success"
      })
    );
  }
  async onCallApiCreateMessagingService() {
    const originCity: any = this.form.originCities.map(
      (item: any) => item.value
    );
    const originCityExclude: any = this.form.originCitiesExclude.map(
      (item: any) => item.value
    );
    const destinationCity: any = this.form.destinationCities.map(
      (item: any) => item.value
    );
    const destinationCityExclude: any = this.form.destinationCitiesExclude.map(
      (item: any) => item.value
    );
    const posId: any = this.form.posPartners.map((item: any) => item.value);
    const posIdExclude: any = this.form.posPartnersExclude.map(
      (item: any) => item.value
    );
    const clientId: any = this.form.clientBranches.map(
      (item: any) => item.value
    );
    const clientParentId: any = this.form.clientParent.map(
      (item: any) => item.value
    );
    const clientIdExclude: any = this.form.clientBranchesExclude.map(
      (item: any) => item.value
    );
    const clientParentIdExclude: any = this.form.clientParentExclude.map(
      (item: any) => item.value
    );
    const slaArr = [
      {
        value: "on_sla",
        formValue: this.form.msgConfOnSla
      },
      {
        value: "over_sla",
        formValue: this.form.msgConfOverSla
      }
    ];
    const slaValue = slaArr
      .filter(sla => sla.formValue)
      .map(sla => sla.value)
      .join(",");

    MainAppController.closeMessageModal();
    const createMsgService = await MessagingServiceConfigurationController.createMessagingConfig(
      {
        name: this.form.messageTitle,
        type: this.form.messageType,
        sttLastStatus: this.form.eventStatus,
        packetType: this.form.packageType,
        target: this.form.target.join(),
        bookedFor: this.form.appliedTo.join(),
        product: this.form.product.join(),
        originCity: originCity.join(),
        origincityExclude: originCityExclude.join(),
        posId: posId.join(),
        posIdExclude: posIdExclude.join(),
        clientId: [clientId.includes("All") ? "All" : clientId].join(),
        clientIdExclude: [...clientIdExclude].join(),
        schedulePeriodeStart: this.$moment(this.form.startDate).format(
          "YYYY-MM-DD"
        ),
        schedulePeriodeEnd: this.$moment(this.form.endDate).format(
          "YYYY-MM-DD"
        ),
        messageText: this.form.messageDetail,
        templateNameWaPremium: this.form.templateName,
        msgConfIntracity: this.form.msgConfIntracity,
        msgConfIntercity: this.form.msgConfIntercity,
        clientParentId: [...clientParentId].join(),
        clientParentIdExclude: [...clientParentIdExclude].join(),
        destinationCity: destinationCity.join(),
        destinationCityExclude: destinationCityExclude.join(),
        sla: slaValue,
        reason: this.form.reasons.map(e => e.value)
      }
    );

    if (createMsgService) {
      this.showSuccessModal();
    }
  }

  async onCallApiPatchMessagingService() {
    MainAppController.closeMessageModal();
    const result = await MessagingServiceConfigurationController.patchMessagingServiceConfiguration();
    if (result) {
      this.showSuccessModal();
    }
  }

  get isPosFormValid() {
    if (this.form.appliedTo.includes("pos")) {
      return this.form.posPartners.length > 0;
    }
    return true;
  }
  get isClientFormValid() {
    if (this.form.appliedTo.includes("client")) {
      return (this.form.clientBranches.length > 0 || this.form.clientParent.length > 0);
    }
    return true;
  }

  get reasonsHasSelected(): boolean {
    return this.form.eventStatus?.toLowerCase().match(/cnx|dex|codrej/)
      ? !!this.form.reasons?.length
      : true;
  }

  get isFormValid() {
    return (
      this.form.startDate &&
      this.form.endDate &&
      this.form.messageType &&
      this.form.eventStatus &&
      this.form.packageType &&
      this.form.target.length > 0 &&
      this.form.appliedTo.length > 0 &&
      this.form.product.length > 0 &&
      this.form.originCities.length > 0 &&
      this.form.destinationCities.length > 0 &&
      this.isPosFormValid &&
      this.isClientFormValid &&
      this.form.messageDetail && (this.form.msgConfIntracity || this.form.msgConfIntercity) &&
      (this.form.msgConfOnSla || this.form.msgConfOverSla) &&
      this.reasonsHasSelected
    );
  }
}
