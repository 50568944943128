/* eslint-disable @typescript-eslint/camelcase */
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  MessagingServiceConfigurationList,
  MessagingServiceConfigurationForm,
  MessagingServiceConfigurationListData,
  MessageDictionary,
  TemplateWhatsappPremium
} from "@/domain/entities/MessagingServiceConfiguration";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import { MessagingServiceConfigurationPresenter } from "../presenters/MessagingServiceConfigurationPresenter";
import {
  AddMessagingServiceConfigurationApiRequest,
  EditMessagingServiceConfigurationApiRequest,
  RequestMessagingServiceConfigurationList
} from "@/data/payload/api/MessagingServiceConfigurationApiRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { OptionsClass } from "@/domain/entities/MainApp";

export interface MessagingServiceConfigurationState {
  isLoading: boolean;
  messageServiceForm: MessagingServiceConfigurationForm;
}

export interface MessagingServiceConfigurationState {
  isLoading: boolean;
  shohowModalConfirmation: boolean;
  showModalSuccess: boolean;
}
@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "messaging-service-configuration"
})
class MessagingServiceConfigurationStore extends VuexModule
  implements MessagingServiceConfigurationState {
  public isLoading = false;
  public error = false;
  public errorCause = "";
  public advancedFilterData = {
    type: { name: "", value: "" },
    messagingEventStatus: { name: "", value: "" },
    messagingMessageStatus: { name: "", value: "" }
  };
  public messageServiceList = new MessagingServiceConfigurationList(
    new Pagination(1, 20),
    []
  );
  public messageServiceDetail = new MessagingServiceConfigurationListData();

  @Action
  getMessageServiceList(params: RequestMessagingServiceConfigurationList) {
    this.setLoading(true);
    this.setErrorCause("");
    const presenter = container.resolve(MessagingServiceConfigurationPresenter);
    presenter
      .getMessagingServiceConfigurationList(params)
      .then(res => this.setMessageServiceList(res))
      .catch(err => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  fetchMessageServiceList() {
    this.getMessageServiceList(
      new RequestMessagingServiceConfigurationList({
        page: this.messageServiceList.pagination.page,
        limit: this.messageServiceList.pagination.limit,
        status: this.advancedFilterData.messagingMessageStatus.value,
        messageType: this.advancedFilterData.type.value,
        eventStatus: this.advancedFilterData.messagingEventStatus.value,
        isTotalData: true,
        version: "2"
      })
    );
  }

  @Action
  public initMessageServiceList() {
    this.setFirstPage();
    this.setAdvancedFilterData({
      type: { name: "", value: "" },
      messagingEventStatus: { name: "", value: "" },
      messagingMessageStatus: { name: "", value: "" }
    });
    this.fetchMessageServiceList();
  }

  @Action
  getMessageServiceDetail(id: number) {
    this.setLoading(true);
    this.setErrorCause("");
    const presenter = container.resolve(MessagingServiceConfigurationPresenter);
    presenter
      .getMessagingServiceConfigurationDetail(id)
      .then(res => this.setMessageServiceDetail(res))
      .catch(err => this.setErrorCause(err.response ? "server" : "internet"))
      .finally(() => this.setLoading(false));
  }

  @Mutation
  setFirstPage() {
    this.messageServiceList.pagination.limit = 20;
    this.messageServiceList.pagination.page = 1;
  }

  @Mutation
  setMessageServiceList(val: MessagingServiceConfigurationList) {
    this.messageServiceList = val;
  }

  @Mutation
  setMessageServiceDetail(val: MessagingServiceConfigurationListData) {
    this.messageServiceDetail = val;
  }

  @Mutation
  setAdvancedFilterData(val: any) {
    this.advancedFilterData = val;
  }

  @Mutation
  setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  setErrorCause(val: string) {
    this.errorCause = val;
  }

  @Mutation
  setError(val: boolean) {
    this.error = val;
  }

  public messageServiceForm = new MessagingServiceConfigurationForm();
  public shohowModalConfirmation = false;
  public showModalSuccess = false;

  @Action
  public createMessagingConfig(params: {
    name: string;
    type: string;
    sttLastStatus: string;
    packetType: string;
    target: string;
    bookedFor: string;
    product: string;
    originCity: string;
    origincityExclude: string;
    posId: string;
    posIdExclude: string;
    clientId: string;
    clientIdExclude: string;
    schedulePeriodeStart: string;
    schedulePeriodeEnd: string;
    messageText: string;
    templateNameWaPremium: string;
    msgConfIntracity: boolean;
    msgConfIntercity: boolean;
    clientParentId: string;
    clientParentIdExclude: string;
    destinationCity: string;
    destinationCityExclude: string;
    sla: string;
    reason: string[];
  }) {
    this.setShowModalConfirmation(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();

    const presenter = container.resolve(MessagingServiceConfigurationPresenter);
    const payload = new AddMessagingServiceConfigurationApiRequest(
      params.name,
      params.type,
      params.sttLastStatus,
      params.packetType,
      params.target,
      params.bookedFor,
      params.product,
      params.originCity,
      params.origincityExclude,
      params.posId,
      params.posIdExclude,
      params.clientId,
      params.clientIdExclude,
      params.schedulePeriodeStart,
      params.schedulePeriodeEnd,
      params.messageText,
      params.templateNameWaPremium,
      params.msgConfIntracity,
      params.msgConfIntercity,
      params.clientParentId,
      params.clientParentIdExclude,
      params.destinationCity,
      params.destinationCityExclude,
      params.sla,
      params.reason
    );

    return presenter
      .createMessagingConfig(payload)
      .then(() => {
        this.setShowModalSuccess(true);
        return true;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Pembuatan Gagal !", () =>
            this.createMessagingConfig(params)
          )
        );
        this.setShowModalSuccess(false);
        return false;
      })
      .finally(() => {
        this.setShowModalConfirmation(false);
        MainAppController.closeLoading();
      });
  }

  @Mutation
  public setShowModalConfirmation(status: boolean) {
    this.shohowModalConfirmation = status;
  }

  @Mutation
  public setShowModalSuccess(status: boolean) {
    this.showModalSuccess = status;
  }

  @Mutation
  setMessageServiceForm(data: MessagingServiceConfigurationForm) {
    this.messageServiceForm = data;
  }

  @Action
  async fetchDetailOnEdit(id: number) {
    this.setLoading(true);
    this.setError(false);
    this.setErrorCause("");
    const presenter = container.resolve(MessagingServiceConfigurationPresenter);
    presenter
      .getMessagingServiceConfigurationDetail(id)
      .then((res: MessagingServiceConfigurationListData) => {
        this.setMessageServiceForm(
          new MessagingServiceConfigurationForm({
            configId: res.msgConfId,
            messageTitle: res.msgConfName,
            messageType: res.msgConfType,
            eventStatus: res.msgConfSttLastStatus,
            packageType: res.msgConfPacketType,
            target: res.msgConfTarget.trim().split(","),
            appliedTo: res.msgConfBookedFor.split(","),
            product: res.msgConfProduct.split(","),
            originCities: res.msgConfOriginCity.split(",").map((city: any) => {
              const parsedCity = city.split(" - ");
              const cityCode = parsedCity[0];
              const cityName =
                parsedCity[0] === "All"
                  ? "All Origin City"
                  : `${cityCode} - ${parsedCity[1]}`;
              return {
                name: cityName,
                value: cityCode
              };
            }),
            originCitiesExclude: res.msgConfOriginCityExclude
              .split(",")
              .filter((city: any) => city !== "-" && city !== "")
              .map((city: any) => {
                const parsedCity = city.split(" - ");
                const cityCode = parsedCity[0];
                const cityName = `${cityCode} - ${parsedCity[1]}`;
                return {
                  name: cityName,
                  value: cityCode
                };
              }),
            destinationCities: res.msgConfDestinationCity.split(",").map((city: any) => {
              const parsedCity = city.split(" - ");
              const cityCode = parsedCity[0];
              const cityName =
                parsedCity[0] === "All"
                  ? "All Destination City"
                  : `${cityCode} - ${parsedCity[1]}`;
              return {
                name: cityName,
                value: cityCode
              };
            }),
            destinationCitiesExclude: res.msgConfDestinationCityExclude
            .split(",")
            .filter((city: any) => city !== "-" && city !== "")
            .map((city: any) => {
              const parsedCity = city.split(" - ");
              const cityCode = parsedCity[0];
              const cityName = `${cityCode} - ${parsedCity[1]}`;
              return {
                name: cityName,
                value: cityCode
              };
            }),
            posPartners: res.msgConfPosName
              .split(";")
              .filter((pos: any) => pos !== "-" && pos !== "")
              .map((pos: any, index: number) => {
                return {
                  name: pos === "All" ? "All POS" : pos,
                  value:
                    pos === "All"
                      ? pos
                      : Number(res.msgConfPosId.toString().split(",")[index])
                };
              }),
            posPartnersExclude: res.msgConfPosNameExclude
              .split(";")
              .filter((pos: any) => pos !== "-" && pos !== "")
              .map((pos: any, index: number) => {
                return {
                  name: pos,
                  value: Number(
                    res.msgConfPosIdExclude.toString().split(",")[index]
                  )
                };
              }),
            clientBranches: res.msgConfClientName
              .split(";")
              .filter((client: any) => client !== "-" && client !== "")
              .map((client: any, index: number) => {
                return {
                  name: client === "All" ? "All Client Branch" : client,
                  value:
                    client === "All"
                      ? client
                      : Number(res.msgConfClientId.toString().split(",")[index])
                };
              }),
            clientBranchesExclude: res.msgConfClientNameExclude
              .split(";")
              .filter((client: any) => client !== "-" && client !== "")
              .map((client: any, index: number) => {
                return {
                  name: client,
                  value: Number(
                    res.msgConfClientIdExclude.toString().split(",")[index]
                  )
                };
              }),
            startDate: new Date(res.msgConfSchedulePeriodeStart),
            endDate: new Date(res.msgConfSchedulePeriodeEnd),
            messageDetail: res.msgConfMessageText,
            msgConfIntracity: res.msgConfIntracity,
            msgConfIntercity: res.msgConfIntercity,
            clientParent: res.msgConfClientParentName
            .split(";")
            .filter((client: any) => client !== "-" && client !== "")
            .map((client: any, index: number) => {
              return {
                name: client === "All" ? "All Client Parent" : client,
                value:
                  client === "All"
                    ? ""
                    : Number(res.msgConfClientParentId.toString().split(",")[index])
              };
            }),
            clientParentExclude: res.msgConfClientParentNameExclude
            .split(";")
            .filter((client: any) => client !== "-" && client !== "")
            .map((client: any, index: number) => {
              return {
                name: client,
                value: Number(
                  res.msgConfClientParentIdExclude.toString().split(",")[index]
                )
              };
            }),
            msgConfOnSla: res.msgConfSla.includes("on_sla"),
            msgConfOverSla: res.msgConfSla.includes("over_sla"),
            reasons: res.msgConfReason.map(
              e =>
                new OptionsClass({
                  name: e,
                  value: e
                })
            )
          })
        );
      })
      .catch(err => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  async fetchMessageDictionary() {
    const presenter = container.resolve(MessagingServiceConfigurationPresenter);
    return presenter
      .getMessageDictionary()
      .then((res: MessageDictionary) => {
        return res;
      })
      .catch(() => {
        return new MessageDictionary();
      });
  }

  @Action
  initMessageForm() {
    this.setMessageServiceForm(new MessagingServiceConfigurationForm());
  }

  @Action
  async patchMessagingServiceConfiguration() {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(MessagingServiceConfigurationPresenter);
    return presenter
      .patchMessagingConfig(
        new EditMessagingServiceConfigurationApiRequest({
          ...this.messageServiceForm,
          reason: this.messageServiceForm.reasons.map(e => e.value)
        })
      )
      .then(() => {
        return true;
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Gagal !", () => {
            this.patchMessagingServiceConfiguration();
            MainAppController.closeErrorMessage();
          })
        );
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  async fetchTemplateWhatsappPremium(params: any) {
    const presenter = container.resolve(MessagingServiceConfigurationPresenter);
    return presenter
      .getTemplateWhatsappPremium(params)
      .then((res: TemplateWhatsappPremium[]) => {
        return res;
      })
      .catch(() => {
        return [];
      });
  }
}

export const MessagingServiceConfigurationController = getModule(
  MessagingServiceConfigurationStore
);
